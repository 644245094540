import React, { useState, useEffect } from "react"
import { View, StyleSheet, ScrollView, SafeAreaView,  Platform, Dimensions, TextInput, TouchableOpacity} from "react-native"
import { Box } from 'react-native-flex-layout';
import { Auth, API } from 'aws-amplify';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { KeyboardAccessoryNavigation } from 'react-native-keyboard-accessory'
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Separator from '../components/Separator';
import ChipSection from "../components/ChipSection";
import MonetaImageGallery from "../components/MonetaImageGallery";
import Util from "../util/util.js"
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import {Chip_Styles} from "../style/ChipStyles.js"
import { fonts } from "../style/fonts";


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const ReportDetails = (props) => {

    // Gets navigation parameters passed from previous screen
    const { isClosed, isUrgent, timestamp, comments, content, organization, topic, uuid, reporter, HROs, index, KPIs, images, locations} = props.route.params;
    
    const [ImageURLs, setImageURLs] = useState([])

    const [Comments, setComments] = useState(comments)

    const [commentInputText, setCommentInputText] = useState('');

    useEffect(() => {
        if(images && images.length > 0){
          // get urls of images from lambda
          getImageURLs(images).then((urls) => {
            console.log("images updated")
            setImageURLs(() => urls)
          })
        }
    }, [images])


    const getImageURLs = async (images) => {
        const getImageURLPromises = images.map(imageID => Util.getImageURL(imageID))
        const ImageURLs = await Promise.all(getImageURLPromises)
        console.log(ImageURLs)
        return ImageURLs
    }

        //Takes a timestamp and comment, and calls addComment endpoint
        const addComment = async (timestamp, comment) => {
            const currentUser = await Auth.currentAuthenticatedUser()
            const token = currentUser.signInUserSession.idToken.jwtToken
            const requestData = {
              headers: {
                Authorization: token
              },
              queryStringParameters: {
                uuid: uuid,
                timestamp: timestamp,
                comment: JSON.stringify(comment),
                IsNew: true,
                reportId: Util.formatUuid(uuid),
                setFirstCommentDate: (comments === 'No comments yet' || comments.length == 0),
                commentDate: Date.now()
              }
            }
      
            try{ 
              const data = await API.post('Resty','/items/addComment', requestData)
              Toast.show('✅ Comment added', {
                backgroundColor: "#259e23",
                position: Toast.positions.BOTTOM,
                duration: 2500,
                textColor: "#fff",
            });
      
              await Util.setReportCache(uuid, "reportChanged", true)
              await Util.setReportCache(uuid, "reportCommented", true)
              return(data.items) // This is could perhaps lead to undefined behavior :/
            }
            catch(error){
              Util.printAPIError(error)
            }
            return([])
          }

    const postCommentToEntry = async () => {
        if (!commentInputText.trim()) { return; }
  
        // await Util.setReportCache(uuid, "reportChanged", true)
  
        //get text in textbox
  
        //call /addComment endpoint with the new comment
  
        //the endpoint will append the comment to the list of comments
        try {
          const userT = await Auth.currentAuthenticatedUser()
          const token = userT.signInUserSession.idToken.jwtToken
          var localcomments = Comments

          const newCommentData = {
            comment: commentInputText,
            commenterIsAdmin: false
          }
  
          if(Comments.length == 0){
            localcomments = [newCommentData]
            setComments(() => localcomments)
          }else{
            localcomments = [...Comments, newCommentData]
            setComments(() => localcomments)
          }
  
          addComment(timestamp,commentInputText).then(result => {
            setCommentInputText(() => "")
            //disabling navigating away for now
            //props.navigation.pop()
            //setText(JSON.stringify(result, null, 2));
          }).catch(error => {
            Util.printAPIError(error)
          })
        } catch (e) {
          console.log(e.message);
          console.log(Comments)
        }
      };

    return (
        <View style={[Platform.OS === 'web' ? webStyles.container : mobileStyles.container, {height:screenHeight/1}]}>
            <Header organization={organization} admin={false} type='pop' />

            {/* Global header  */}
            <View style={{ flex: 2, paddingHorizontal: wp(6), paddingTop: hp(1.7), height: screenHeight/1}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Title  */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomText font='medium' size={24} color={'#000'}>Report {Util.formatUuid(uuid)}</CustomText>
                    </View>
                    <View style={{maxWidth: 100, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        {isUrgent && !isClosed &&
                          <Box style={webStyles.urgentTag}>
                            <CustomText style={webStyles.TagText} font='medium' size={14} color={'white'}> URGENT</CustomText>
                          </Box> 
                        }                   
                        {isClosed &&
                          <Box style={webStyles.closedTag}>
                            <CustomText style={webStyles.TagText} font='medium' size={14} color={'white'}> CLOSED</CustomText>
                          </Box> 
                        }

                    </View>
                </View>
                <Separator />

                {/* Main content scrollview  */}

                <ScrollView style={{flex: 1}} contentContainerStyle={{}}>

                    {/* Description  */}
                    {/*<CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Description</CustomText>*/}

                    <SafeAreaView>
                        <View style={styles.orangeContainer}>
                            <ScrollView style={{}} contentContainerStyle={{}}>
                                <CustomText font='medium' size={20} color={'#000'}>{content}</CustomText>
                            </ScrollView>
                        </View>
                        <CustomText style={{ textAlign: 'left', marginTop: hp(1), marginBottom: hp(0), paddingVertical: hp(0) }} font='medium' size={15} color={'#000'}>Sent: {Util.timeConverter(timestamp)}</CustomText>
                        <CustomText style={{ textAlign: 'left', marginTop: hp(0) }} font='medium' size={15} color={'#000'}>From: {reporter ? reporter : "Anonymous"}</CustomText>
                    </SafeAreaView>

                    <MonetaImageGallery
                      images = {ImageURLs}
                    />

                    {/* KPIs */}
                    <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Hazard Classifications</CustomText>
                    {
                        KPIs && KPIs.length > 0 ? <ChipSection styles={Chip_Styles} Chips={KPIs.sort()} doSynchronize={false}></ChipSection> :
                        <CustomText style={{ textAlign: 'left', marginTop: hp(0), marginBottom: hp(0), paddingVertical: hp(0) }} font='medium' size={15} color={'#ccc'}>No Hazard Classifications</CustomText>
                    }

                    {/* Location */}
                    <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Location</CustomText>
                    {locations && locations.length > 0 ?
                      <ChipSection styles={Chip_Styles} Chips={locations.sort()} doSynchronize={false}></ChipSection> :
                      <CustomText style={{ textAlign: 'left', marginTop: hp(0), marginBottom: hp(0), paddingVertical: hp(0) }} font='medium' size={15} color={'#ccc'}>No Location</CustomText>
                    }


                    {/* Follow up  */}
                    <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Follow up</CustomText>

                    <SafeAreaView style={Platform.OS === "web" ? webStyles.commentsContainer : mobileStyles.commentsContainer}>
                    {Comments && Comments.length > 0 ? Comments.map((element, index) => {
                            return (
                                <View style={[Platform.OS === "web" ? webStyles.commentContainer : mobileStyles.commentContainer, typeof(element) === 'string' || element.commenterIsAdmin ? {} : Platform.OS === 'web' ? webStyles.secondaryCommentContainer : mobileStyles.secondaryCommentContainer]}>
                          <View style={{flex: 1 }}>
                          {(typeof(element) === 'string' || element.commenterIsAdmin) && <CustomText style={webStyles.primaryCommentUserName}>Admin </CustomText>}
                          {(typeof(element) !== 'string' && !element.commenterIsAdmin) && <CustomText style={Platform.OS === 'web' ? webStyles.secondaryCommentUserName : mobileStyles.secondaryCommentUserName}>Reporter </CustomText>}
                          {(typeof(element) !== 'string') && <CustomText style={element.commenterIsAdmin ? webStyles.primaryCommentDateTime : Platform.OS === 'web'? webStyles.secondaryCommentDateTime : mobileStyles.secondaryCommentDateTime}>{Util.timeConverter(element.commentDate)}</CustomText>}
                          </View>
                            <View key={index} style={[styles.orangeContainer2, typeof(element) === 'string' || element.commenterIsAdmin ? webStyles.primaryComment: Platform.OS === 'web' ? webStyles.secondaryComment : mobileStyles.secondaryComment]}>
                                <ScrollView style={{}} contentContainerStyle={{}}>
                                    <CustomText font='medium' size={20} color={'#000'}>{typeof(element) === 'string' ? element :  element.comment}</CustomText>
                                </ScrollView>
                            </View></View>
                            )
                        }) :
                        <CustomText style={{ textAlign: 'left', marginTop: hp(0), marginBottom: hp(0), paddingVertical: hp(0) }} font='medium' size={15} color={'#ccc'}>No Comments Yet</CustomText>
                    }
                    </SafeAreaView>

                                    {/* Submit */}
                {!isClosed && Comments.length > 0 && <SafeAreaView style={{ marginBottom: hp(2.5) }}>
                     <View style={styles.orangeContainer}>
                        <TextInput allowFontScaling={false} editable multiline numberOfLines={3} onChangeText={(textBoxInput2) => setCommentInputText(textBoxInput2)} value={commentInputText} placeholder="Add comment" placeholderTextColor={'rgba(0,0,0,0.35)'} style={styles.textInput} />
                    </View>
                    <TouchableOpacity onPress={postCommentToEntry}>
                        <View style={styles.button}>
                            <CustomText font='medium' size={15} color={'#000'}>Comment</CustomText>
                        </View>
                    </TouchableOpacity>
                </SafeAreaView>}


                    {/* Report UUID  */}
                    <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Report's Unique ID</CustomText>

                    <SafeAreaView>
                        <View style={styles.orangeContainer2}>
                            <ScrollView style={{}} contentContainerStyle={{}}>
                                <CustomText font='medium' size={20} color={'#000'}>{uuid}</CustomText>
                            </ScrollView>
                        </View>
                    </SafeAreaView>

                </ScrollView>


            </View>
            <Footer admin={false} />
            <KeyboardAccessoryNavigation androidAdjustResize avoidKeyboard />
        </View>
    )
}

const styles = StyleSheet.create({
    orangeContainer: {
        borderRadius: 12,
        backgroundColor: 'rgba(255, 196, 81, 0.3)',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: wp(3.2),
        marginTop: hp(1),
        maxHeight: hp(40),
        minHeight: hp(17.8),
        borderColor: 'rgb(100,100,100)',
        borderWidth: 1,
    },
    orangeContainer2: {
        borderRadius: 12,
        backgroundColor: 'rgba(255, 196, 81, 0.3)',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: wp(3.2),
        marginTop: hp(1),
        maxHeight: hp(40),
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
    },
    textInput: {
        width: '100%', 
        fontFamily: fonts.POPPINS.medium, 
        fontSize: 24, 
        color: '#000',
        flex: 1
      },
    button: {
        cursor: 'pointer',
        backgroundColor: '#FFC451',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(6.8),
        width: '100%',
        alignSelf: 'center',
        marginTop: hp(2.5),
    },
    buttonDelete: {
        cursor: 'pointer',
        backgroundColor: '#eb4129',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(6.8),
        width: '50%',
        alignSelf: 'center',
        marginTop: hp(2.5),
    },
    heading: {
        marginTop: hp(1),
    },
    KPIChip:{
        marginHorizontal: hp(0),
        backgroundColor: 'rgba(255,196,81,0.3)',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
    },
    KPIChipSelected:{
        marginHorizontal: hp(0),
        backgroundColor: '#FFC451',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
    },
    KPIChipAdd:{
        marginHorizontal: hp(0),
        borderRadius: 100,
        backgroundColor: '#CCCCCC',
        justifyContent: 'center',
        flex: 1,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
    },
    KPIText:{
        marginHorizontal: hp(2),
    },
    KPIDeleteText:{
        marginRight: hp(1),
        borderRadius: 100,
    }
})

export default ReportDetails;