import React, { useState, useEffect } from "react"
import { View, StyleSheet, TouchableOpacity, ScrollView, SafeAreaView, TextInput, Platform, Dimensions} from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { Box } from 'react-native-flex-layout';
import { Feather } from '@expo/vector-icons';
import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Separator from '../components/Separator';
import { fonts } from "../style/fonts";
import { Auth, API } from 'aws-amplify';
import { KeyboardAccessoryNavigation } from 'react-native-keyboard-accessory'
import Toast from 'react-native-root-toast';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Util from "../util/util.js"
import ChipSection from "../components/ChipSection.js";
import MonetaImageGallery from "../components/MonetaImageGallery";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import {Chip_Styles, Web_Chip_Styles, AI_Chip_Styles} from "../style/ChipStyles.js"

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .6
}

const AdminReportDetails = (props) => {
    // Gets navigation parameters passed from previous screen
    var { isClosed, isUrgent, timestamp, comments, content, organization, uuid, reporter, HROs, index, KPIs, locations, HRO_AI, KPI_AI, images } = props.route.params;

    const [commentInputText, setCommentInputText] = useState('');

    const [validKPIs, setValidKPIs] = useState([""])
    const [KPIS, setKPIS] = useState([])

    // Since there's only a few HROs, and they aren't likely to change, hard-coding rather
    // than making extra API calls
    const [validHROs, setValidHROs] = useState([
      "Preoccupation with Failure", 
      "Reluctance to Simplify", 
      "Sensitivity to Operations", 
      "Commitment to Resilience", 
      "Deference to Expertise"
    ])

    const HROCodeLookup = {
      "Preoccupation with Failure": "PF",
      "Reluctance to Simplify": "RS",
      "Sensitivity to Operations": "SO", 
      "Commitment to Resilience": "CR", 
      "Deference to Expertise": "DE",        
    }
    const HROLookup = {};
    for (const [hro, code] of Object.entries(HROCodeLookup)) {
      HROLookup[code] = hro;
    }

    const [HROS, setHROS] = useState([])

    const [validLocations, setValidLocations] = useState([""])
    const [Locations, setLocations] = useState([])


    const [Comments, setComments] = useState([])
    const [Images, setImages] = useState([])
    const [ImageURLs, setImageURLs] = useState([])
    const [galleryIndex, setGalleryIndex] = useState(0);

    const [shouldUpdateKPIS, setShouldUpdateKPIS] = useState(false)
    const [shouldUpdateHROS, setShouldUpdateHROS] = useState(false)
    const [shouldUpdateLocations, setShouldUpdateLocations] = useState(false)

    const [isEditing, setIsEditing] = useState(false);
    const [isResolved, setIsResolved] = useState(isClosed === true );

    const [AIHROs, setAIHROs] = useState(() => {
      if(HRO_AI){
        return([HROLookup[HRO_AI]])
      }else{
        return([])
      }
    })
    const [AIKPIs, setAIKPIs] = useState(() => {
      if(KPI_AI){
        return([KPI_AI])
      }else{
        return([])
      }
    })

    const getValidChips = async (chip_type) => {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const requestData = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          organization: organization,
          value_type: chip_type
        }
      }
      try{ 
        const data = await API.get('Resty','/items/organization_configuration/value_type', requestData)
        //sort chips by value, but return including metadata
        return(data.items.sort((a,b) => {
          const upperA = a.value.toUpperCase()
          const upperB = b.value.toUpperCase()

          if (upperA < upperB) {
            return -1;
          }
          if (upperA > upperB) {
            return 1;
          }
          return 0;
        }))
      }
      catch(error){
        Util.printAPIError(error)
      }
      return([])
    }

    const isReportChanged = async () => {
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        if(allKeys.includes("reportChanged")){
          var reportChanged = JSON.parse(await AsyncStorage.getItem("reportChanged"))
          return(reportChanged.includes(uuid))
        }else{
          return(false)
        }
      }catch(error){
        console.log(error)
        console.log("isReportChanged ERROR")
      }
      return(false)
    }

    const setReportChanged = async (changed=true) => {
      try{
        const allKeys = await AsyncStorage.getAllKeys();
        if(allKeys.includes("reportChanged")){
          if(changed){
            var reportChanged = JSON.parse(await AsyncStorage.getItem("reportChanged"))
            if(!(reportChanged.includes(uuid))){
              reportChanged.push(uuid)
              await AsyncStorage.setItem("reportChanged",JSON.stringify(reportChanged))
            }
          }else{
            var reportChanged = JSON.parse(await AsyncStorage.getItem("reportChanged"))
            if(reportChanged.includes(uuid)){
              reportChanged = reportChanged.filter(e => e != uuid)
              await AsyncStorage.setItem("reportChanged",JSON.stringify(reportChanged))
            }
          } 
        }else{
          if(changed){
            await AsyncStorage.setItem("reportChanged",JSON.stringify([uuid]))
          }else{
            //Do nothing
          }
        }
      }catch(error){
        console.log("SET REPORT CHANGED ERROR")
        console.log(error)
      }
    }

    const getCurrentReport = async () => {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const requestData = {
        headers: {
          Authorization: token
        },
      }
      try{ 
        const data = await API.get('Resty','/items/getFromUUID/'+uuid, requestData)
        return(data.items[0])
      }
      catch(error){
        Util.printAPIError(error)
      }
    }

    const TagPatch = async (input, dataKey) => {
      await setReportChanged(true)
      const currentUser = await Auth.currentAuthenticatedUser()
      const token = currentUser.signInUserSession.idToken.jwtToken

      const queryStringParameters = {
        uuid: uuid,
        timestamp: timestamp,
      } 
      queryStringParameters[dataKey.toLowerCase()] = JSON.stringify(input)

      const requestData = {
        headers: {
          Authorization: token
        },
        queryStringParameters: queryStringParameters
      }

      try{ 
        const data = await API.patch('Resty',`/items/object/${dataKey}`, requestData)
        Toast.show('✅ Tags updated', {
          backgroundColor: "#259e23",
          duration: 2500,
          textColor: "#fff",
        });
        return(data) // This is could perhaps lead to undefined behavior :/
      }
      catch(error){
        Util.printAPIError(error)
      }
      return([])
    }

    //Upon startup
    useEffect(() => {
      isReportChanged().then(changed=>{
        if(changed){
          getCurrentReport().then(currentReport => {
            setIsResolved(currentReport.isClosed === true)
            if(currentReport.HROs){
              const hros= currentReport.HROs.map(hro => HROLookup[hro]);
              setHROS(() => hros)
            }
            if(currentReport.KPIs){
              setKPIS(() => currentReport.KPIs.sort())
            }
            if(currentReport.comments){
              setComments(() => currentReport.comments)
            }

            if(currentReport.locations){
              setLocations(() => currentReport.locations)
            }})
        }else{        
          if(typeof HROs == "object" && HROs.length > 0){
            const hros = HROs.map(hro => HROLookup[hro]);
            setHROS(hros)
          }else{
            setHROS([])
          }
          if(typeof KPIs == "object" && KPIs.length > 0){
            setKPIS(KPIs.sort())
          }else{
            setKPIS([])
          }
          if(typeof comments == "object" && comments.length > 0){
            setComments(comments)
          }else{
            setComments(() => [])
          }
          if(typeof locations == "object" && locations.length > 0){
            setLocations(locations)
          }else{
            setLocations(() => [])
          }
          if(typeof images == "object" && images.length > 0){
            setImages(images)
          }else{
            setImages(() => [])
          }
          
        }
        //Update Valid KPIs so user can select from them
        getValidChips("KPI").then(e => {
          const textOnly = e.map((value, index) => {
            return value["value"] //Only keep the content of the KPI and not metadata
          })
          setValidKPIs(() => textOnly)
        })
        
        getValidChips("Location").then(e => {
          const textOnly = e.map((value, index) => {
            return value["value"] //Only keep the content of the KPI and not metadata
          })
          setValidLocations(() => textOnly)
        })
      })
    }, [])

    useEffect(() => {
      if(shouldUpdateKPIS){
        TagPatch(KPIS, 'KPIs').then((e) => console.log(e))
        setShouldUpdateKPIS(false)
      }
    }, [KPIS, shouldUpdateKPIS])

    useEffect(() => {
      if(shouldUpdateHROS){
        const hroCodes = HROS.map(hro => HROCodeLookup[hro]);
        TagPatch(hroCodes, 'HROs').then((e) => console.log(e))
        setShouldUpdateHROS(false)
      }
    }, [HROS, shouldUpdateHROS])

    useEffect(() => {
      if(shouldUpdateLocations){
        TagPatch(Locations, 'Locations').then((e) => console.log(e))
        setShouldUpdateLocations(false)
      }
    }, [Locations, shouldUpdateLocations])

    useEffect(() => {
      if(images && images.length > 0){
        // get urls of images from lambda
        getImageURLs(images).then((urls) => {
          setImageURLs(() => urls)
        })
      }
    }, [images])

    const getImageURLs = async (images) => {
      const getImageURLPromises = images.map(imageID => Util.getImageURL(imageID))
      const ImageURLs = await Promise.all(getImageURLPromises)
      return ImageURLs
    }

    //we were notified of a deletion. Send patch to API
    const deleteKPIs = () => {
      setShouldUpdateKPIS(true)
    }

    //we were notified of an addition. Send patch to API
    const addKPI = () => {
      setShouldUpdateKPIS(true)
    }

    //we were notified of a deletion. Send patch to API
    const deleteLocations = () => {
      setShouldUpdateLocations(true)
    }

    //we were notified of an addition. Send patch to API
    const addLocation = () => {
      setShouldUpdateLocations(true)
    }

    //we were notified of a deletion. Send patch to API
    const deleteHROs = () => {
      setShouldUpdateHROS(true)
    }

    //we were notified of an addition. Send patch to API
    const addHRO = () => {
      setShouldUpdateHROS(true)
    }

    //Takes a timestamp and comment, and calls addComment endpoint
    const addComment = async (timestamp, comment) => {
      const currentUser = await Auth.currentAuthenticatedUser()
      const token = currentUser.signInUserSession.idToken.jwtToken
      const requestData = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          uuid: uuid,
          timestamp: timestamp,
          comment: JSON.stringify(comment),
          IsNew: true,
          reportId: Util.formatUuid(uuid),
          setFirstCommentDate: (comments === 'No comments yet' || comments.length == 0),
          commentDate: Date.now()
        }
      }

      try{ 
        const data = await API.post('Resty','/items/addComment', requestData)
        Toast.show('✅ Comment added', {
          backgroundColor: "#259e23",
          position: Toast.positions.BOTTOM,
          duration: 2500,
          textColor: "#fff",
      });

        await Util.setReportCache(uuid, "reportChanged", true)
        await Util.setReportCache(uuid, "reportCommented", true)
        return(data.items) // This is could perhaps lead to undefined behavior :/
      }
      catch(error){
        Util.printAPIError(error)
      }
      return([])
    }

    const closeOrReopen = async () => {
      const currentUser = await Auth.currentAuthenticatedUser()
      const token = currentUser.signInUserSession.idToken.jwtToken

      const requestData = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          uuid: uuid,
          timestamp: timestamp,
          reportId: Util.formatUuid(uuid),
          isClosed: !isResolved
        }
      }

      let data = {}
      try {
        data = await API.patch('Resty','/items/close', requestData)
        await Util.setReportCache(uuid, "reportChanged", true)
        await Util.setReportCache(uuid, "reportClosed", !isResolved)
        await Util.setReportCache(uuid, "reportReopened", isResolved)

      } catch(error) {
        data["error"] = error      
      }
      
      if (data.error) {
        let message = `Failed to ${!isResolved ? 'Close' : 'Reopen'} Report`
        Toast.show(message, {
          backgroundColor: "red",
          duration: 2500,
          textColor: "#fff",
        })
        return    
      }

      setIsResolved(!isResolved)

      let message = `✅ Report ${!isResolved ? 'Closed' : 'Reopened'}`
      Toast.show(message, {
        backgroundColor: "#259e23",
        duration: 2500,
        textColor: "#fff",
      })
    }

    const postCommentToEntry = async () => {
      if (!commentInputText.trim()) { return; }

      await Util.setReportCache(uuid, "reportChanged", true)

      //get text in textbox

      //call /addComment endpoint with the new comment

      //the endpoint will append the comment to the list of comments
      try {
        const userT = await Auth.currentAuthenticatedUser()
        const token = userT.signInUserSession.idToken.jwtToken
        var localcomments = Comments

        if(Comments.length == 0){
          localcomments = [commentInputText]
          setComments(() => localcomments)
        }else{
          localcomments = [...Comments,commentInputText]
          setComments(() => localcomments)
        }

        addComment(timestamp,commentInputText).then(result => {
          setCommentInputText(() => "")
          //disabling navigating away for now
          //props.navigation.pop()
          //setText(JSON.stringify(result, null, 2));
        }).catch(error => {
          Util.printAPIError(error)
        })
      } catch (e) {
        console.log(e.message);
        console.log(Comments)
      }
    };

    if(Platform.OS === 'web'){
      return (
        <View style={[webStyles.container, {height: screenHeight/1}]}>
            {/* Global header  */}
            <Header organization={organization} admin={true} type='pop' />
            <ScrollView style={{height: screenHeight/1}}>
              {/* Close */}
              <View>
                <TouchableOpacity onPress={close}>
                  <View style={styles.closeButton}>
                    <CustomText font='medium' size={15} color={'white'}>Close Report</CustomText>
                  </View>
                </TouchableOpacity>
              </View>
            <View style={{ flex: 1, paddingHorizontal: wp(6), paddingTop: hp(1.7),height: screenHeight/1}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Title  */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomText font='medium' size={24} color={'#000'}>Report {Util.formatUuid(uuid)}:</CustomText>
                    </View>
                    <View style={{maxWidth: 100, flexDirection: 'row', justifyContent: 'space-between',  alignItems: 'center' }}>
                      {isUrgent && !isResolved &&
                        <Box style={webStyles.urgentTag}>
                          <CustomText style={webStyles.TagText} font='medium' size={14} color={'white'}> URGENT</CustomText>
                        </Box> 
                      }
                      {isResolved &&
                        <Box style={webStyles.closedTag}>
                          <CustomText style={webStyles.TagText} font='medium' size={14} color={'white'}> CLOSED</CustomText>
                        </Box> 
                      }
                  </View>
                </View>

                <Separator />

                <SafeAreaView>
                    <View style={styles.orangeContainer}>
                        <ScrollView style={{}} contentContainerStyle={{}}>
                            <CustomText font='medium' size={20} color={'#000'}>{content}</CustomText>
                        </ScrollView>
                    </View>
                    <CustomText style={{ textAlign: 'left', marginTop: hp(1), marginBottom: hp(0), paddingVertical: hp(0) }} font='medium' size={15} color={'#000'}>Sent: {Util.timeConverter(timestamp)}</CustomText>
                    <CustomText style={{ textAlign: 'left', marginTop: hp(0) }} font='medium' size={15} color={'#000'}>From: {reporter ? reporter : "Anonymous"}</CustomText>
                </SafeAreaView>

                {/* Display images to be uploaded */}
                <MonetaImageGallery
                  images = {ImageURLs}
                />
                {(AIKPIs && AIKPIs.length > 0) && <View>
                  <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>AI Hazard Classifications</CustomText>
                  <ChipSection styles={AI_Chip_Styles} 
                    doSynchronize={false} 
                    canAdd={false} 
                    canDelete={false} 
                    Chips={AIKPIs}></ChipSection>
                </View>}
                
                {(AIHROs && AIHROs.length > 0) && <View>
                  <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>AI HROs</CustomText>
                  <ChipSection styles={AI_Chip_Styles} 
                    doSynchronize={false} 
                    canAdd={false} 
                    canDelete={false} 
                    Chips={AIHROs}></ChipSection>
                </View>}

                {/* Edit Button  */}
                <TouchableOpacity onPress = {() => {setIsEditing(!isEditing)}}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: isEditing ? "#FFF" : "#FFF"}}>
                      <CustomText font='medium' size={24} color={'#000'} style={[styles.heading, {marginRight: wp(1)}]}>Editable Tags</CustomText>
                      <Feather name='edit' size={24} color={isEditing ? 'rgba(255, 196, 81, 0.3)' : '#000'} style={{marginTop: wp(.5)}} />
                  </View>
                </TouchableOpacity>

                {/* KPIs */}
                {((KPIS && KPIS.length > 0) || (isEditing)) &&
                <View>
                  <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Hazard Classifications</CustomText>
                  <ChipSection styles={Web_Chip_Styles} 
                    deleteChips={() => deleteKPIs()} 
                    addChip={() => addKPI()} 
                    doSynchronize={isEditing} 
                    canAdd={isEditing} 
                    canDelete={isEditing} 
                    sortChips={true}
                    validValues={validKPIs}
                    Chips={KPIS}
                    setChips={setKPIS}></ChipSection>
                </View>}

                {/* HROs */}
                {((HROS && HROS.length > 0) || (isEditing)) &&
                <View>
                  <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>HROs (Admin Override)</CustomText>
                  <ChipSection styles={Web_Chip_Styles}
                    deleteChips={() => deleteHROs()} 
                    addChip={() => addHRO()} error
                    doSynchronize={isEditing} 
                    canAdd={isEditing && HROS.length == 0} 
                    canDelete={isEditing} 
                    validValues={validHROs}
                    Chips={HROS}
                    setChips={setHROS}></ChipSection>
                </View>}

                {/* Location */}
                {((Locations && Locations.length > 0) || (isEditing)) &&
                <View>
                  <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Location</CustomText>
                  <ChipSection styles={Web_Chip_Styles}
                    deleteChips={() => deleteLocations()} 
                    addChip={() => addLocation()} 
                    doSynchronize={isEditing} 
                    canAdd={isEditing} 
                    canDelete={isEditing} 
                    validValues={validLocations}
                    Chips={Locations}
                    setChips={setLocations}></ChipSection>
                </View>
                }

                {/* Follow up  */}
                <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Follow up</CustomText>
                {/* Resolve */}
                <View>
                  <TouchableOpacity delayLongPress={1000} onLongPress={closeOrReopen}>
                    <View style={isResolved ? styles.reopenButton : styles.resolveButton}>
                      <CustomText font='medium' size={15} color={'white'}>{isResolved ? 'Reopen' : 'Close'} Report</CustomText>
                    </View>
                  </TouchableOpacity>
                </View>
                <SafeAreaView style={webStyles.commentsContainer}>
                {
                  !Comments || Comments.length == 0 ?
                    <View style={styles.orangeContainer2}>
                      <CustomText font='medium' size={20} color={'#000'}>{"No Comments Yet"}</CustomText>
                    </View> :
                    Comments.map((element, index) => {
                        return (
                          <View style={[webStyles.commentContainer, typeof(element) === 'string' || element.commenterIsAdmin ? {} : webStyles.secondaryCommentContainer]}>
                            <View style={{flex: 1 }}>
                              {(typeof(element) === 'string' || element.commenterIsAdmin) && <CustomText style={webStyles.primaryCommentUserName}>Admin </CustomText>}
                              {(typeof(element) !== 'string' && !element.commenterIsAdmin) && <CustomText style={webStyles.secondaryCommentUserName}>Reporter </CustomText>}
                              {(typeof(element) !== 'string') && <CustomText style={element.commenterIsAdmin ? webStyles.primaryCommentDateTime : webStyles.secondaryCommentDateTime}>{Util.timeConverter(element.commentDate)}</CustomText>}
                            </View>
                          <View key={index} style={[styles.orangeContainer2, typeof(element) === 'string' || element.commenterIsAdmin ? webStyles.primaryComment: webStyles.secondaryComment]}>     
                              <ScrollView style={{}} contentContainerStyle={{}}>
                                  <CustomText style={{}} font='medium' size={20} color={'#000'}>{typeof(element) === 'string' ? element :  element.comment}</CustomText>
                              </ScrollView>
                          </View>
                        </View>
                        )
                    })
                }
                </SafeAreaView>

                {/* Submit */}
                <SafeAreaView style={{ marginBottom: hp(2.5) }}>
                    <View style={[styles.orangeContainer2, webStyles.addComment]}>
                        <TextInput allowFontScaling={false} editable multiline numberOfLines={3} onChangeText={(textBoxInput2) => setCommentInputText(textBoxInput2)} value={commentInputText} placeholder="Add comment" placeholderTextColor={'rgba(0,0,0,0.35)'} style={styles.textInput} />
                    </View>
                    <TouchableOpacity onPress={postCommentToEntry}>
                        <View style={styles.button}>
                            <CustomText font='medium' size={15} color={'#000'}>Comment</CustomText>
                        </View>
                    </TouchableOpacity>
                </SafeAreaView>

                {/* Report UUID  */}
                <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Report's Unique ID</CustomText>


                <SafeAreaView>
                    <View style={styles.orangeContainer2}>
                        <ScrollView style={{}} contentContainerStyle={{}}>
                            <CustomText font='medium' size={20} color={'#000'}>{uuid}</CustomText>
                        </ScrollView>
                    </View>
                </SafeAreaView>
            </View>
            </ScrollView>
            <Footer admin={true} />
          </View>
    )
    }else{
      return (
        <View style={[mobileStyles.container, {height:screenHeight/1}]}>
            {/* Global header  */}
            <Header organization={organization} admin={true} type='pop' />
            <View style={{ flex: 1, paddingHorizontal: wp(6), paddingTop: hp(1.7), height: screenHeight/1}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Title  */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomText font='medium' size={24} color={'#000'}>Report {Util.formatUuid(uuid)}:</CustomText>
                    </View>
                    <View style={{maxWidth: 100, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      {isUrgent && !isResolved &&
                        <Box style={webStyles.urgentTag}>
                          <CustomText style={webStyles.TagText} font='medium' size={14} color={'white'}> URGENT</CustomText>
                        </Box> 
                      }
                      {isResolved &&
                        <Box style={webStyles.closedTag}>
                          <CustomText style={webStyles.TagText} font='medium' size={14} color={'white'}> CLOSED</CustomText>
                        </Box> 
                      }
                  </View>
                </View>
                <Separator />

                {/* Main content scrollview  */}

                <ScrollView style={{}} contentContainerStyle={{}} >

                    {/* Description  */}
                    {/*<CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Description</CustomText>*/}

                    <SafeAreaView>
                        <View style={styles.orangeContainer}>
                            <ScrollView style={{}} contentContainerStyle={{}}>
                                <CustomText font='medium' size={20} color={'#000'}>{content}</CustomText>
                            </ScrollView>
                        </View>
                        <CustomText style={{ textAlign: 'left', marginTop: hp(1), marginBottom: hp(0), paddingVertical: hp(0) }} font={fonts.POPPINS.regular} size={15} color={'#000'}>Sent: {Util.timeConverter(timestamp)}</CustomText>
                        <CustomText style={{ textAlign: 'left', marginTop: hp(0) }} font='medium' size={15} color={'#000'}>From: {reporter ? reporter : "Anonymous"}</CustomText>
                    </SafeAreaView>

                    {/* Display images to be uploaded */}
                    <MonetaImageGallery
                      images = {ImageURLs}
                      setImages = {setImageURLs}
                    />

                    {(AIKPIs && AIKPIs.length > 0) && <View>
                      <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>AI Hazard Classifications</CustomText>
                      <ChipSection styles={AI_Chip_Styles} 
                        doSynchronize={false} 
                        canAdd={false} 
                        canDelete={false} 
                        Chips={AIKPIs}></ChipSection>
                    </View>}
                    
                    {(AIHROs && AIHROs.length > 0) && <View>
                      <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>AI HROs</CustomText>
                      <ChipSection styles={AI_Chip_Styles} 
                        doSynchronize={false} 
                        canAdd={false} 
                        canDelete={false} 
                        Chips={AIHROs}></ChipSection>
                    </View>}

                    {/* Edit Button  */}
                    <TouchableOpacity onPress = {() => {setIsEditing(!isEditing)}}>
                      <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: isEditing ? "#FFF" : "#FFF"}}>
                          <CustomText font='medium' size={24} color={'#000'} style={[styles.heading, {marginRight: wp(1), marginTop: wp(8)}]}>Editable Tags</CustomText>
                          <Feather name='edit' size={24} color={isEditing ? 'rgba(255, 196, 81, 0.3)' : '#000'} style={{marginTop: wp(8.5)}} />
                      </View>
                    </TouchableOpacity>

                    {/* KPIs */}
                    {((KPIS && KPIS.length > 0) || (isEditing)) &&
                    <View>
                      <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Hazard Classifications</CustomText>
                      <ChipSection styles={Chip_Styles} 
                        deleteChips={() => deleteKPIs()} 
                        addChip={() => addKPI()} 
                        doSynchronize={isEditing} 
                        canAdd={isEditing} 
                        canDelete={isEditing} 
                        sortChips={true}
                        validValues={validKPIs}
                        Chips={KPIS}
                        setChips={setKPIS}></ChipSection>
                    </View>}

                    {/* HROs */}
                    {((HROS && HROS.length > 0) || (isEditing)) &&
                    <View>
                      <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>HROs</CustomText>
                      <ChipSection styles={Chip_Styles}
                        deleteChips={() => deleteHROs()} 
                        addChip={() => addHRO()} error
                        doSynchronize={isEditing} 
                        canAdd={isEditing && HROS.length == 0} 
                        canDelete={isEditing} 
                        validValues={validHROs}
                        Chips={HROS}
                        setChips={setHROS}></ChipSection>
                    </View>}

                    {/* Location */}
                    {((Locations && Locations.length > 0) || (isEditing)) &&
                    <View>
                      <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Location</CustomText>
                      <ChipSection styles={Chip_Styles}
                        deleteChips={() => deleteLocations()} 
                        addChip={() => addLocation()} 
                        doSynchronize={isEditing} 
                        canAdd={isEditing} 
                        canDelete={isEditing} 
                        validValues={validLocations}
                        Chips={Locations}
                        setChips={setLocations}></ChipSection>
                    </View>
                    }

                    {/* Follow up  */}
                    <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Follow up</CustomText>
                      {/* Resolve */}
                      <View>
                        <TouchableOpacity delayLongPress={1000} onLongPress={closeOrReopen}>
                          <View style={isResolved ? styles.reopenButton : styles.resolveButton}>
                            <CustomText font='medium' size={15} color={'white'}>{isResolved ? 'Reopen' : 'Close'} Report</CustomText>
                          </View>
                        </TouchableOpacity>
                      </View>

                    <SafeAreaView style={mobileStyles.commentsContainer}>
                      {
                        !Comments || Comments.length == 0 ?
                          <View style={styles.orangeContainer2}>
                            <CustomText font='medium' size={20} color={'#000'}>{"No Comments Yet"}</CustomText>
                          </View> :
                          Comments.map((element, index) => {
                              return (
                                <View style={[mobileStyles.commentContainer, typeof(element) === 'string' || element.commenterIsAdmin ? {} : mobileStyles.secondaryCommentContainer]}>
                                  <View style={{flex: 1}}>
                                    {(typeof(element) === 'string' || element.commenterIsAdmin) && <CustomText style={webStyles.primaryCommentUserName}>Admin </CustomText>}
                                    {(typeof(element) !== 'string' && !element.commenterIsAdmin) && <CustomText style={mobileStyles.secondaryCommentUserName}>Reporter </CustomText>}
                                    {(typeof(element) !== 'string') && <CustomText style={element.commenterIsAdmin ? webStyles.primaryCommentDateTime : mobileStyles.secondaryCommentDateTime}>{Util.timeConverter(element.commentDate)}</CustomText>}
                                  </View>
                                  <View key={index} style={[styles.orangeContainer2, typeof(element) === 'string' || element.commenterIsAdmin ? webStyles.primaryComment: mobileStyles.secondaryComment]}>
                                    <ScrollView style={{}} contentContainerStyle={{}}>
                                        <CustomText font='medium' size={20} color={'#000'}>{typeof(element) === 'string' ? element :  element.comment}</CustomText>
                                    </ScrollView>
                                  </View>
                                </View>
                              )
                          })
                      }
                    </SafeAreaView>

                    {/* Submit */}
                    <SafeAreaView style={{ marginBottom: hp(2.5) }}>                  
                        <View style={styles.orangeContainer}>
                            <TextInput allowFontScaling={false} editable multiline numberOfLines={3} onChangeText={(textBoxInput2) => setCommentInputText(textBoxInput2)} value={commentInputText} placeholder="Add comment" placeholderTextColor={'rgba(0,0,0,0.35)'} style={styles.textInput} />
                        </View>
                        <TouchableOpacity onPress={postCommentToEntry}>
                            <View style={styles.button}>
                                <CustomText font='medium' size={15} color={'#000'}>Submit</CustomText>
                            </View>
                        </TouchableOpacity>
                    </SafeAreaView> 

                    {/* Report UUID  */}
                    <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Report's Unique ID</CustomText>

                    <SafeAreaView>
                        <View style={styles.orangeContainer2}>
                            <ScrollView style={{}} contentContainerStyle={{}}>
                                <CustomText font='medium' size={20} color={'#000'}>{uuid}</CustomText>
                            </ScrollView>
                        </View>
                    </SafeAreaView>

                </ScrollView>


            </View>
            <Footer admin={true} />
            <KeyboardAccessoryNavigation androidAdjustResize avoidKeyboard />
        </View>
    )
    }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  orangeContainer: {
      borderRadius: 12,
      backgroundColor: 'rgba(255, 196, 81, 0.3)',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: wp(3.2),
      marginTop: hp(1),
      maxHeight: hp(40),
      minHeight: hp(17.8),
      borderColor: 'rgb(100,100,100)',
      borderWidth: 1,
  },
  orangeContainer2: {
      borderRadius: 12,
      backgroundColor: 'rgba(255, 196, 81, 0.3)',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: wp(3.2),
      marginTop: hp(1),
      maxHeight: hp(40),
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
  },
  textInput: {
    width: '100%', 
    fontFamily: fonts.POPPINS.medium, 
    fontSize: 24, 
    color: '#000',
    flex: 1
  },
  button: {
      cursor: 'pointer',
      backgroundColor: '#FFC451',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: hp(6.8),
      width: '100%',
      alignSelf: 'center',
      marginTop: hp(2.5),
  },
  reopenButton: {
    cursor: 'pointer',
    backgroundColor: 'green',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(4),
    marginTop: 2,
    maxWidth: 150
  },
  resolveButton: {
    cursor: 'pointer',
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(4),
    marginTop: 2,
    maxWidth: 150
  },
  heading: {
      marginTop: hp(1 * scale),
  },
})

export default AdminReportDetails;