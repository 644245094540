import React, { useState, useEffect } from "react"
import { List } from 'react-native-paper';
import { KeyboardAvoidingView, Keyboard, View, StyleSheet, Image, TouchableWithoutFeedback, TextInput, ScrollView, Platform, ActivityIndicator, Dimensions } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText.js";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import Separator from "../components/Separator.js";
import { fonts } from "../style/fonts.js";
import { Auth, API} from 'aws-amplify';
import { KeyboardAccessoryNavigation } from 'react-native-keyboard-accessory'
import Toast from 'react-native-root-toast';


import MonetaImageGallery from "../components/MonetaImageGallery.js";


import { TouchableOpacity } from "react-native-gesture-handler";
import Util from "../util/util.js"


import { Feather } from "@expo/vector-icons";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";

const screenWidth = Dimensions.get('window').width;
let scale = 1
// Adjust fonts for viewing the web portal on smaller devices
if (screenWidth < 800 && Platform.OS === "web") {
  scale = .7
}

const SubmitBug = (props) => {
    const { organization, admin } = props.route.params;

    // Defining local state value
    const [textBoxInput2, setTextBoxInput2] = useState('');

    const [imagesToSend, setImagesToSend] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([])//Required for upload successful images button

    const [uploadProgress, setUploadProgress] = useState({})

    const [uploadStage, setUploadStage] = useState("Picking");

    const [imageLoading, setImageLoading] = useState(false)   

    const [errors, setErrors] = useState({}); 
    const [isFormValid, setIsFormValid] = useState(false); 
    const [displayErrors, setDisplayErrors] = useState(false); 


    useEffect(() => { 
      // Require description
      validateForm(); 
    }, [textBoxInput2]); 

    const validateForm = () => { 
      let errors = {}; 

      // Require description
      if (!textBoxInput2.trim()) { 
          errors.description = 'Description is required.'; 
      } 

      // Set the errors and update form validity 
      setErrors(errors); 
      setIsFormValid(Object.keys(errors).length === 0); 
    }; 

    //Update uploadProg dict when image is picked or dropped - this should never happen during upload time
    useEffect(() => {
      var uploadProg = {};
      imagesToSend.forEach((value, index) => {
        uploadProg[value] = "Picked";
      })
      setUploadProgress(() => uploadProg);
    }, [imagesToSend])

    const postBug = async () => {
      // Validate form before proceeding. This will prevent invalid reports from being submitted.
      setDisplayErrors(true);
      if (!isFormValid) { return; } 

      try {
        const user = await Auth.currentAuthenticatedUser()
        let [fullImageURLs, _] = await Util.getUploadedImageURLs(true, uploadedImages, imagesToSend, setUploadedImages, setUploadStage, setUploadProgress)

        let userEmail = user.attributes.email
        // Build email, and send it with bug report info
        let details = "<b>Sent:</b> " + Util.timeConverter(Date.now()) + "<br/>" +
                      "<b>User:</b> " + userEmail + "<br />" +
                      "<b>Organization:</b> " + organization + "<br /><br />" +
                      "<b>Description</b>: " + textBoxInput2 + "<br />" 

        if (fullImageURLs.length > 0) {
          details += "<br/><br/><b>Screenshots:</b><br/>"
          for (const img in fullImageURLs) {
            details += `<img src="cid:unique@screenshot-${img}" style="width: 100px"/><br/>`      
          }         
        }

        let message = "A bug report was submitted.<br/><br/>"

        Util.sendEmailNotification(details, message, 'bugSubmitted', organization, userEmail, fullImageURLs)

        Toast.show('✅ Bug report submitted', {
          backgroundColor: "#259e23",
          position: Toast.positions.BOTTOM,
          duration: 2500,
          textColor: "#fff",
      });

      props.navigation.pop() 

      } catch (e) {
        alert("❌ Failed to submit bug report: ", e.message)
      }
    };

    const selectPhotoOptions = async () => {
      // No need to offer options for submitting screenshots. They will always
      // be in the gallery.
      Util.pickImage(Util.imageMode.gallery, setImageLoading, setImagesToSend)
    }

    return (
      <View style={[Platform.OS === 'web' && screenWidth >= 800 ? webStyles.container : mobileStyles.container, styles.container]}>
      <Header organization={organization} admin={admin} type='pop' />
      {uploadStage && (uploadStage == "Uploading" || uploadStage == "Finished" || uploadStage == "Failed") &&
        <ScrollView containerStyle = {{alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
        }}>
          {
            uploadProgress && Object.keys(uploadProgress).length > 0 && 
            <View style={{borderRadius: 12, borderColor: '#000', borderWidth: 1, height: hp(80), margin: wp(5), backgroundColor: "#ffffff", alignItems: 'center', flexDirection: 'column'}}>
              { Platform.OS == "web" ?
                  <View style={{flex: 0, paddingBottom: hp(10)}}>
                    <CustomText style={{}} font='medium' size={25} color={'#000'}>Upload Progress</CustomText>
                  </View> :

                  <View style={{flex: 0}}>
                    <CustomText style={{}} font='medium' size={25} color={'#000'}>Upload Progress</CustomText>
                  </View>
                }
                            
              {Object.keys(uploadProgress).map((key, index) => {
                const status = uploadProgress[key]
                var indicator = ""
                console.log(status)
                console.log("key: ",key)
                switch(status){
                  case "Picked":
                    indicator = <Feather name='arrow-up-circle' size={24} color={'#000'} style={{}} />
                    break
                  case "Uploading":
                    indicator = <ActivityIndicator size="large"/>
                    break
                  case "Failed":
                    indicator = <Feather name='alert-circle' size={24} color={'#000'} style={{}} />
                    break
                  case "Finished":
                    indicator = <Feather name='check-circle' size={24} color={'#000'} style={{}} />
                    break
                }

                if(Platform.OS=="web" && screenWidth >= 800){
                  return(
                    <View style={{flexDirection: 'row'}}>
                      <Image style={{height: wp(7), width: wp(7)}} source={{uri: key}}/>
                      <CustomText style={{ marginLeft: wp(5), paddingHorizontal: wp(5), flex: 5 }} font='medium' size={20} color={'#000'}>Image {index + 1}: {uploadProgress[key]}</CustomText>
                      <View style={{ marginLeft: wp(5), paddingHorizontal: wp(5), flex: 1 }}>
                        {indicator}
                      </View>
                    </View>
                  );
                }else{
                  return(
                    <View style={{flexDirection: 'row'}}>
                      <Image style={{height: wp(7), width: wp(7)}} source={{uri: key}}/>
                      <CustomText style={{ marginLeft: wp(3), flex: 5 }} font='medium' size={20} color={'#000'}>Image {index + 1}: {uploadProgress[key]}</CustomText>
                      <View style={{ marginLeft: wp(3), flex: 1 }}>
                        {indicator}
                      </View>
                    </View>
                  );
                }
              })}
              {(uploadStage == "Failed" || Util.anyUploadsFailed(uploadProgress)) && <View>
                <TouchableOpacity onPress={() => {setUploadStage("Picking")}} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFC451', borderRadius: 12, marginVertical: hp(1)}}>
                  <CustomText style={styles.buttonText} font='medium' size={20} color={'#000'}>Cancel</CustomText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {postBug()}} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFC451', borderRadius: 12, marginVertical: hp(1)}}>
                  <CustomText style={styles.buttonText} font='medium' size={20} color={'#000'}>Retry</CustomText>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {postBug()}} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFC451', borderRadius: 12, marginVertical: hp(1)}}>
                  <CustomText style={styles.buttonText} font='medium' size={20} color={'#000'}>Submit with successful uploads</CustomText>
                </TouchableOpacity>
              </View>}
            </View>
          }
      </ScrollView>
      }
      
      <ScrollView style={styles.baseContainer}>
        {/* Global header  */}
        

        {/* So pressing anywhere on the screen will dismiss the keyboard while typing in a report 
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false} >*/}
        <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
          <View style={{flex: 1}}>
            <View style={{ paddingHorizontal: wp(6), paddingTop: hp(1.7)}}>
              {/* Title  */}
              <CustomText style={{ textAlign: 'center' }} font='medium' size={32} color={'#000'}>Submit bug report</CustomText>
              <Separator />

              <CustomText size={14}>Contact Us: support@monetatech.net </CustomText>
              
              {/* Description textbox  */}
              <KeyboardAvoidingView style={styles.orangeContainer}>
                {textBoxInput2 === '' && <CustomText style={styles.placeholder}>Describe the issue. Include as much detail as possible.</CustomText>}
                <TextInput allowFontScaling={false} editable multiline numberOfLines={8} onChangeText={(textBoxInput2) => setTextBoxInput2(textBoxInput2)} placeholder="" style={styles.textInput} />
              </KeyboardAvoidingView>

              <CustomText style={styles.error}>{displayErrors ? errors.description: null}</CustomText>

              {/* Select Image to upload */}
              <TouchableOpacity onPress={selectPhotoOptions} style={styles.imageUploadButton}>
                <CustomText style={styles.buttonText} font='medium' size={32} color={'#000'}>Upload Screenshots {(imageLoading) &&  <ActivityIndicator  size="small"/>}</CustomText>
              </TouchableOpacity>

              <MonetaImageGallery
                images = {imagesToSend}
                setImages = {setImagesToSend}
                allowDeletion = {true}
              />

              {/* Submit button  */}
              <TouchableOpacity style={Platform.OS === 'web' && screenWidth >= 800 ? [styles.submitButton, {width: 300 }] : styles.submitButton} onPress={() => postBug()}>
                <CustomText style={styles.buttonText} font='medium' size={32} color={'#000'}>Submit</CustomText>
              </TouchableOpacity>
            </View>

            {/* FAQ */}
            <View style={{ paddingHorizontal: wp(2.5), paddingTop: hp(1)}}>        
              <List.Section >
              <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>FAQ</CustomText>
                <List.Accordion
                  title="How do I use voice to text?">
                  <List.Section>
                    <CustomText font='medium' style={ styles.accordionText }>
                    Voice to text uses your device's built-in functionality. Location will vary by device, 
                    but you can look for the microphone icon on your Apple or Android.
                    </CustomText>
                    <Image source={require('../assets/images/voice-to-text.jpg')} style={ styles.faqSingleScreenshot }/>
                  </List.Section>
                </List.Accordion>
                <List.Accordion
                  title="How do I upload a new picture?">
                  <List.Section>
                    <CustomText font='medium' style={ styles.accordionText }>
                      When you click on 'Select Images to Upload' using a mobile device, you will get an option
                      to choose whether to use your device's built-in camera to take a new picture or your photo gallery 
                      to upload an existing image.
                    </CustomText>
                    <Image source={require('../assets/images/camera.jpg')} style={ styles.faqSingleScreenshot }/>
                  </List.Section>
                </List.Accordion>
                <List.Accordion
                  title="My camera isn't opening?">
                  <List.Section>
                    <CustomText font='medium' style={ styles.accordionText }> 
                      Likely, your permissions are not enabled for MonetaTech. We've seen this happen sometimes on Android 
                      devices. Go to Settings {'->'} Apps & notifications {'->'}                     
                      (If this happens on your Apple device, go to Settings {'->'} Camera and make sure MonetaTech is enabled.)
                    </CustomText>
                    <View style={ styles.faqMultiScreenshotContainer }>
                      <Image source={require('../assets/images/apps_notifications.jpg')} style={ styles.faqMultiScreenshot }/>
                      <Image source={require('../assets/images/moneta_android.jpg')} style={ styles.faqMultiScreenshot }/>
                    </View>
                    <View style={ styles.faqMultiScreenshotContainer }>
                      <Image source={require('../assets/images/permissions.jpg')} style={ styles.faqMultiScreenshot }/>
                      <Image source={require('../assets/images/camera_permissions.jpg')} style={ styles.faqMultiScreenshot }/>
                    </View>
                    <View style={ styles.faqMultiScreenshotContainer }>
                      <Image source={require('../assets/images/allow_camera.jpg')} style={ styles.faqMultiScreenshot }/>
                    </View>
                  </List.Section>
                </List.Accordion>
              </List.Section>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      <Footer  admin={admin}/>
      <KeyboardAccessoryNavigation androidAdjustResize avoidKeyboard />
    </View>
  )
}

const styles = StyleSheet.create({
  orangeContainer: {
    //flex: 1,
    backgroundColor: 'rgba(255, 196, 81, 0.3)',
    //alignItems: 'center',
    borderRadius: 12,
    marginTop: hp(4.5),
    height: hp(30),
    width: '100%'
  },
  textInput: {
    width: '100%', 
    textAlign: 'left', 
    textAlignVertical: 'top',
    fontFamily: fonts.POPPINS.medium, 
    fontSize: 24,
    flex: 1, 
    paddingLeft: wp(2),
    paddingTop: hp(2.4)
  },
  placeholder: {
    position: 'absolute', 
    top: 5, 
    alignSelf: 'center',
    color: 'rgba(0,0,0,0.35)',
    fontSize: Platform.OS == "web" ? 24 * scale : 22,
    marginLeft: 5
  },
  buttonDelete: {
    cursor: 'pointer',
    backgroundColor: '#FF1111',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(6.8),
    width: '50%',
    alignSelf: 'center',
    marginTop: hp(2.5),
  },
  buttonAdd: {
    cursor: 'pointer',
    backgroundColor: '#11FF11',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(6.8),
    width: '50%',
    alignSelf: 'center',
    marginTop: hp(2.5),
  },
  submitButton: {
    cursor: 'pointer', 
    justifyContent: 'center', 
    alignItems: 'center', 
    alignSelf: 'center', 
    backgroundColor: '#FFC451', 
    borderRadius: 12, 
    marginVertical: hp(2),
    width: '100%',
    height: 70, 
  },
  imageUploadButton: {
    height: 70, 
    cursor: 'pointer', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: '#FFC451', 
    borderRadius: 12, 
    marginVertical: hp(1)
  },
  accordionText: {
    marginLeft: 15, 
    arginBottom: 5
  },
  faqSingleScreenshot: {
    alignSelf: 'center', 
    maxWidth: 300, 
    width: wp(60), 
    height: hp(55)
  },
  faqMultiScreenshotContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-evenly', 
    alignItems: 'center'
  },
  faqMultiScreenshot: {
    maxWidth: 300, 
    width: wp(40), 
    height: hp(35)
  },
  heading: {
    marginTop: hp(1),
  },
  Chip:{
    marginHorizontal: hp(0),
    backgroundColor: 'rgba(255,196,81,0.3)',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipSelected:{
    marginHorizontal: hp(0),
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipAdd:{
    marginHorizontal: hp(0),
    borderRadius: 100,
    backgroundColor: '#CCCCCC',
    justifyContent: 'center',
    flex: 1,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
    minHeight: hp(3),
  },
  ChipText:{
    marginHorizontal: hp(2),
  },
  ChipDeleteText:{
    marginRight: hp(1),
    borderRadius: 100,
  },
  container: {
    height: "100%",
    position: "absolute",
    zIndex: -1,
  },
  baseContainer: {
    backgroundColor: "white",
    flex: 1,
  },
  tallcontainer: {
    backgroundColor: "white",
    flex: 1,
    height: hp(30)
  },
  buttonText: {
    marginVertical: hp(1.8),
    paddingVertical: hp(.8),
    paddingHorizontal: wp(.8)
  },
  error: {
    color: "red",
    fontWeight: "bold",
    textAlign: "center"
  }
})

export default SubmitBug;